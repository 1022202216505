.dropdown {
  &:focus {
    background: transparent !important;
  }

  color: white !important;
  margin-right: -10px !important;
}

.reportDropdown {
  background: rgba(0, 0, 0, 0.8) !important;

  color: white !important;
}