.user {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(238, 238, 238, 0.41);
    cursor: pointer;

    img {
        height: 70px;
        width: 70px;
        background: #fff;
        object-fit: cover;
    }

    .userName {
        color: #666;
        margin-left: 10px;
        margin-right: 100px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex: 1,
    }

    .unblock {
        margin-right: 20px;
    }
}